import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import CaseDetail from '../components/Cases/CaseDetail'
import Hero from '../components/Hero'
export default class CaseStudyDetailPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Startup Funding"
            description="This is how we got Speabora VC ready"
          />
          <CaseDetail />
        </div>
      </Layout>
    )
  }
}
